import { json, LinksFunction, MetaFunction } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
"@remix-run/react";
import styles from "./styles/tailwind.css";
import toastStyles from "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { withSentry } from "@sentry/remix";
import PublicEnv from "~/components/public-env";
import ErrorMessage from "~/components/ErrorMessage/ErrorMessage";
import nestableStyles from "react-nestable/src/styles/index.css";
import { clientEnvironment } from "~/environment.server";

export function loader() {
  return json(
    {
      publicKeys: clientEnvironment()
    },
    {
      // environment variables won't change often, so we can cache them for a minute
      headers: { "Cache-Control": "private, max-age=60" }
    }
  );
}

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: styles },
{ rel: "stylesheet", href: toastStyles },
{ rel: "stylesheet", href: nestableStyles }];


export const meta: MetaFunction = () => [
{
  title: "Eventur"
},
{ charSet: "utf-8" },
{ viewport: "width=device-width, initial-scale=1" }];


export function ErrorBoundary() {
  return <ErrorMessage />;
}

function App() {
  const data = useLoaderData<typeof loader>();

  return (
    <html
      lang="en"
      className="h-full font-helvetica text-sm text-[#333333] scrollbar-track-transparent scrollbar-thumb-eventur-blue-900">

      <head>
        <Meta />
        <Links />
        <title>Eventur</title>
      </head>
      <body>
        <PublicEnv {...data.publicKeys} />
        <ToastContainer style={{ width: "fit-content" }} />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export default withSentry(App, {
  wrapWithErrorBoundary: true
});